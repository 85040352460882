import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Pine64 går på högvarv igen. Google tar bort en av våra favoritappar från Play Store. Välj och vraka bland OS från en och samma USB-sticka med Ventoy. Det, och mycket mer!`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`PineTab Linux Tablet för $100: `}<a parentName="li" {...{
          "href": "https://liliputing.com/2020/05/pinetab-linux-tablet-goes-up-for-pre-order-this-month-for-100.html"
        }}>{`https://liliputing.com/2020/05/pinetab-linux-tablet-goes-up-for-pre-order-this-month-for-100.html`}</a>{` `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=Ii6lAjgfW3c&"
        }}>{`https://www.youtube.com/watch?v=Ii6lAjgfW3c&`}</a>{` `}</li>
      <li parentName="ul">{`Pine64 har fullt upp: `}<a parentName="li" {...{
          "href": "https://www.pine64.org/2020/05/15/may-update-pinetab-pre-orders-pinephone-qi-charging-more/"
        }}>{`https://www.pine64.org/2020/05/15/may-update-pinetab-pre-orders-pinephone-qi-charging-more/`}</a></li>
      <li parentName="ul">{`Firefox Relay: `}<a parentName="li" {...{
          "href": "https://relay.firefox.com/"
        }}>{`https://relay.firefox.com/`}</a></li>
      <li parentName="ul">{`Half-Life: Alyx nu på Linux: `}<a parentName="li" {...{
          "href": "https://www.gamingonlinux.com/2020/05/half-life-alyx-now-available-on-linux-with-vulkan"
        }}>{`https://www.gamingonlinux.com/2020/05/half-life-alyx-now-available-on-linux-with-vulkan`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Google tar bort PodcastAddict från Play Store: `}<a parentName="li" {...{
          "href": "https://twitter.com/PodcastAddict/status/1261651512947691520"
        }}>{`https://twitter.com/PodcastAddict/status/1261651512947691520`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Ventoy: `}<a parentName="li" {...{
          "href": "https://www.ventoy.net/en/index.html"
        }}>{`https://www.ventoy.net/en/index.html`}</a></li>
      <li parentName="ul">{`Joplin: `}<a parentName="li" {...{
          "href": "https://joplinapp.org/"
        }}>{`https://joplinapp.org/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Har installerat Ubuntu Touch, igen. `}</li>
    </ul>
    <h3>{`Sebs Linux-äventyr`}</h3>
    <ul>
      <li parentName="ul">{`PiHole`}<ul parentName="li">
          <li parentName="ul">{`kan jag jobba med RaspberryPi från min desktop? `}</li>
        </ul></li>
    </ul>
    <h3>{`Vim`}</h3>
    <ul>
      <li parentName="ul">{`Intet nytt på Vim-fronten`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Unixstickers för €1 inkl frakt (tack Christer): `}<a parentName="li" {...{
          "href": "https://www.stickermule.com/eu/products/unixstickers-pro-pack"
        }}>{`https://www.stickermule.com/eu/products/unixstickers-pro-pack`}</a></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      